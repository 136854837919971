
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import uploadCertificateData from "@/layout/header/partials/trainee/uploadCertificateData.vue";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";

export default defineComponent({
  name: "upload-income-certified",
  components: {
    Form,
    Field,
    Datatable,
    Swal,
    uploadCertificateData,
  },
  data() {
    return {
      tableHeader: [
        // {
        //   name: "Sl",
        //   key: "sl",
        //   sortable: true,
        //   width: "2px",
        // },
        {
          name: "Reference Number",
          key: "ref_no",
          sortable: true,
        },
        {
          name: "Registration Number",
          key: "reg_no",
          sortable: true,
        },
        {
          name: "Trainee Name",
          key: "trainee_name",
          sortable: true,
        },
        {
          name: "Mobile",
          key: "mobile",
          sortable: true,
        },
        {
          name: "Gender",
          key: "gender",
          sortable: true,
        },
        {
          name: "File",
          key: "filestatus",
          sortable: true,
        },
        {
          name: "Actions",
          key: "actions",
          sortable: true,
        },
      ],
      uploadStatus: [] as any,

      loading: false,
      courseInfoData: false,
      showCertifiedData: false,
      entity_id: "" as any,
      training_institute_id: "",
      course_info_id: "",
      tranche_id: "" as any,
      batch_info_id: "",
      showtrainingProviderNotice: false,
      institutes: [],
      associations: [] as any,
      tranches: [],
      batches: [],
      courses: [],
      btnCheck: false,
      load: false,
      tableLoad: false,
      componentKey: 0,
      statusKey: 0,
      enrollStatus: "" as any,
      incomecertificateStatus: 0 as any,
    };
  },
  async created() {
    await this.associationList();
    await this.getTranches();
    this.emitter.on("certificate-status-update", async () => {
      await this.uploadIncomeCertified();
    });
  },
  methods: {
    uploadcertificate(traineeId, certificateId = null) {
      let data = [traineeId, certificateId];

      this.emitter.emit("pass-certificate-info", data);
    },
    async btnCheckStatus() {
      if (this.batch_info_id) {
        this.btnCheck = true;
      }
    },
    async getTranches() {
      this.load = true;
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      this.load = true;

      let entity_id = this.entity_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      // console.log(entity_id);
      await ApiService.get("entity/list?entity_id=" + entity_id)
        .then((response) => {
          this.associations = response.data.data;

          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },

    async trainingInstitute() {
      this.load = true;
      let entity = this.entity_id;
      this.training_institute_id = '';
      this.course_info_id = '';
      this.batch_info_id = '';

      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity = VueCookieNext.getCookie("_entity_id");
      }
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get("institute/list?entity_id=" + entity + '&institute_info_id=' +
          institute_info_id)
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getCourseList() {
      this.load = true;
      let entity_id = this.entity_id;
      let institute_info_id = this.training_institute_id;
      this.course_info_id = '';
      this.batch_info_id = '';
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }
      await ApiService.get(
        "course/list?entity_id=" +
          entity_id +
          "&tranche=" +
          this.tranche_id +
          "&institute_info_id=" +
          institute_info_id
      )
        .then((response) => {
          this.courses = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getBatchList() {
      this.load = true;
      let entity_id = this.entity_id;
      this.batch_info_id = '';
      let institute_info_id = this.training_institute_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }
      await ApiService.get(
        "batch/list?entity_id=" +
          entity_id +
          "&course_info_id=" +
          this.course_info_id +
          "&institute_info_id=" +
          institute_info_id
      )
        .then((response) => {
          this.batches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async formSubmit() {
      //form submit
    },
    courseInfo() {
      this.courseInfoData = true;
    },

    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    async uploadIncomeCertified() {
      this.showCertifiedData = true;

      this.tableLoad = true;
      await ApiService.get(
        "trainee/income-certificate-status?entity_id=" +
          this.entity_id +
          "&tranche_id=" +
          this.tranche_id +
          "&training_institute_id=" +
          this.training_institute_id +
          "&course_info_id=" +
          this.course_info_id +
          "&batch_info_id=" +
          this.batch_info_id
      )
        .then((response) => {
          this.incomecertificateStatus = 0 as any;
          this.uploadStatus = response.data.data.trainee_info_data;
          this.uploadStatus.forEach((element) => {
            if (element?.income_certificate) {
              this.incomecertificateStatus += 1;
            }
          });

          this.enrollStatus = response.data.data.enrollment_trainee;
          this.tableLoad = false;

          this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
