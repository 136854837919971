
//import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { Form, Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "trainee_certificate_upload",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      load: false,
      traineeId: "" as any,
      certificate: "" as any,
      certificateId: "" as any,
      componentkey: 0 as any,
    };
  },
  async created() {
    this.emitter.on("pass-certificate-info", async (data) => {
      this.certificate = "" as any;
      this.componentkey += 1;
      if (data[1] != null) {
        this.certificateId = data[1];
        this.traineeId = data[0];
      } else {
        this.traineeId = data[0];
        this.certificateId = null;
      }
    });
  },
  methods: {
    certificateStatus(e) {
      console.log(this.certificate);
      this.certificate = e.target.files[0];
      console.log(this.certificate);
    },
    async certificateUpload() {
      let formData = new FormData();
      formData.set("traineeId", this.traineeId);
      formData.set("certificate", this.certificate);
      if (this.certificateId != null) {
        formData.set("certificate_id", this.certificateId);
      }

      await ApiService.post("trainee/upload-income-certificate", formData)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status == "error") {
              Swal.fire({
                title: "Error!",
                html: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
            } else {
              this.certificate = "" as any;
              DrawerComponent?.hideAll();
              Swal.fire({
                title: "Success!",
                text: response.data.data,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(() => {
                this.emitter.emit("certificate-status-update");
              });
            }
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
        });
    },
  },
});
